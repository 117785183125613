"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var ant_design_vue_1 = require("ant-design-vue");
exports.default = vue_1.defineComponent({
    setup: function () {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var date = now.getDate();
        date = date < 10 ? "0" + date : date;
        var hour = now.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        var minute = now.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        var dateTime = year + "-" + month + "-" + date + " " + hour + ":" + minute;
        var modelRef = vue_1.reactive({
            date: dateTime,
            now_timezone: "America/Los_Angeles",
            timezone: "Asia/Shanghai",
            cycleDate: "",
        });
        var timeZoneList = [];
        fetch("https://vercel.zaojingyoutu.top/api/timezone/", {
            mode: "cors",
            method: "get",
        })
            .then(function (response) { return response.json(); })
            .then(function (res) {
            if (res.code !== 200) {
                ant_design_vue_1.message.error({
                    content: res.msg,
                    duration: 2,
                });
            }
            else {
                for (var i = 0; i < res.data.length; i++) {
                    options.value.push({ value: res.data[i], label: res.data[i] });
                }
                timeZoneList.push(res.data);
            }
        });
        var swap = function () {
            var timezone = modelRef.timezone;
            modelRef.timezone = modelRef.now_timezone;
            modelRef.now_timezone = timezone;
        };
        var onSubmit = function () {
            fetch("https://vercel.zaojingyoutu.top/api/timezone/", {
                mode: "cors",
                method: "post",
                body: JSON.stringify(modelRef),
            })
                .then(function (response) { return response.json(); })
                .then(function (res) {
                if (res.code != 200) {
                    ant_design_vue_1.message.error({
                        content: res.msg,
                        duration: 2,
                    });
                }
                else {
                    ant_design_vue_1.message.success({
                        content: res.msg,
                        duration: 2,
                    });
                    modelRef.cycleDate = res.data;
                }
            });
        };
        var resetFields = function () {
            modelRef.date = "";
            modelRef.timezone = "";
            modelRef.now_timezone = "";
            modelRef.cycleDate = "";
        };
        var options = vue_1.ref([]);
        var handleChange = function (value) {
            console.log("selected " + value);
        };
        var handleBlur = function () {
            console.log("blur");
        };
        var handleFocus = function () {
            console.log("focus");
        };
        var filterOption = function (input, option) {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        return {
            resetFields: resetFields,
            modelRef: modelRef,
            onSubmit: onSubmit,
            timeZoneList: timeZoneList,
            value: vue_1.ref(undefined),
            filterOption: filterOption,
            handleBlur: handleBlur,
            handleFocus: handleFocus,
            handleChange: handleChange,
            options: options,
            swap: swap,
        };
    },
});
