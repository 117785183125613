"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { style: { "margin-top": "8%", "width": "500px", "margin-left": "37%" } };
var vue_3 = require("vue");
var ant_design_vue_1 = require("ant-design-vue");
exports.default = vue_1.defineComponent({
    __name: 'ip',
    setup: function (__props) {
        var formState = vue_3.reactive({
            generateQuantity: "",
            jsonData: "",
        });
        var getIp = function () {
            fetch("https://vercel.zaojingyoutu.top/api/ip-info/" + formState.generateQuantity)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.status == "fail") {
                    ant_design_vue_1.message.error({
                        content: "请输入正确的IP地址！",
                        duration: 2,
                    });
                }
                formState.jsonData = data;
            });
        };
        fetch("https://api64.ipify.org/?format=json")
            .then(function (response) { return response.json(); })
            .then(function (data) {
            console.log(data);
            formState.generateQuantity = data.ip;
            getIp();
        });
        var onFinish = function () {
            formState.jsonData = "";
            getIp();
        };
        var onFinishFailed = function (errorInfo) {
            console.log("Failed:", errorInfo);
        };
        return function (_ctx, _cache) {
            var _component_a_input = vue_2.resolveComponent("a-input");
            var _component_a_form_item = vue_2.resolveComponent("a-form-item");
            var _component_a_button = vue_2.resolveComponent("a-button");
            var _component_a_form = vue_2.resolveComponent("a-form");
            var _component_a_card = vue_2.resolveComponent("a-card");
            return (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                vue_2.createVNode(_component_a_form, {
                    model: formState,
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 16 },
                    onFinish: onFinish,
                    onFinishFailed: onFinishFailed,
                    style: { "display": "flex" }
                }, {
                    default: vue_2.withCtx(function () { return [
                        vue_2.createVNode(_component_a_form_item, {
                            label: "输入IP",
                            name: "generateQuantity",
                            style: { "flex-basis": "auto" },
                            rules: [{ required: true, message: 'IP必填！' }]
                        }, {
                            default: vue_2.withCtx(function () { return [
                                vue_2.createVNode(_component_a_input, {
                                    value: formState.generateQuantity,
                                    "onUpdate:value": _cache[0] || (_cache[0] = function ($event) { return ((formState.generateQuantity) = $event); })
                                }, null, 8, ["value"])
                            ]; }),
                            _: 1
                        }),
                        vue_2.createVNode(_component_a_form_item, { "wrapper-col": { offset: 8, span: 16 } }, {
                            default: vue_2.withCtx(function () { return [
                                vue_2.createVNode(_component_a_button, {
                                    type: "primary",
                                    "html-type": "submit"
                                }, {
                                    default: vue_2.withCtx(function () { return [
                                        vue_2.createTextVNode("获取")
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["model"]),
                vue_2.createElementVNode("div", null, [
                    vue_2.createVNode(_component_a_card, { style: { "width": "365px", "text-align": "justify", "background-color": "#fafafa" } }, {
                        default: vue_2.withCtx(function () { return [
                            vue_2.createElementVNode("p", null, "IP：" + vue_2.toDisplayString(formState.jsonData.query), 1),
                            vue_2.createElementVNode("p", null, "来自：" + vue_2.toDisplayString(formState.jsonData.country), 1),
                            vue_2.createElementVNode("p", null, "城市：" + vue_2.toDisplayString(formState.jsonData.city), 1),
                            vue_2.createElementVNode("p", null, "经度：" + vue_2.toDisplayString(formState.jsonData.lat), 1),
                            vue_2.createElementVNode("p", null, "纬度：" + vue_2.toDisplayString(formState.jsonData.lon), 1),
                            vue_2.createElementVNode("p", null, "时区：" + vue_2.toDisplayString(formState.jsonData.timezone), 1),
                            vue_2.createElementVNode("p", null, "服务商：" + vue_2.toDisplayString(formState.jsonData.isp), 1)
                        ]; }),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
