"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { style: { "margin-top": "8%", "width": "500px", "margin-left": "37%" } };
var vue_3 = require("vue");
exports.default = vue_1.defineComponent({
    __name: 'uuid',
    setup: function (__props) {
        var formState = vue_3.reactive({
            generateQuantity: 1,
            uuids: '',
        });
        var onFinish = function () {
            formState.uuids = '';
            for (var i = 0; i < formState.generateQuantity; i++) {
                formState.uuids = (formState.uuids + '\n' + crypto.randomUUID()).trim();
            }
        };
        var onFinishFailed = function (errorInfo) {
            console.log("Failed:", errorInfo);
        };
        return function (_ctx, _cache) {
            var _component_a_input = vue_2.resolveComponent("a-input");
            var _component_a_form_item = vue_2.resolveComponent("a-form-item");
            var _component_a_button = vue_2.resolveComponent("a-button");
            var _component_a_form = vue_2.resolveComponent("a-form");
            var _component_a_textarea = vue_2.resolveComponent("a-textarea");
            return (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                vue_2.createVNode(_component_a_form, {
                    model: formState,
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 16 },
                    onFinish: onFinish,
                    onFinishFailed: onFinishFailed,
                    style: { "display": "flex" }
                }, {
                    default: vue_2.withCtx(function () { return [
                        vue_2.createVNode(_component_a_form_item, {
                            label: "生成数量",
                            name: "generateQuantity",
                            style: { "flex-basis": "auto" },
                            rules: [{ required: true, message: '生成数量必填！' }]
                        }, {
                            default: vue_2.withCtx(function () { return [
                                vue_2.createVNode(_component_a_input, {
                                    value: formState.generateQuantity,
                                    "onUpdate:value": _cache[0] || (_cache[0] = function ($event) { return ((formState.generateQuantity) = $event); })
                                }, null, 8, ["value"])
                            ]; }),
                            _: 1
                        }),
                        vue_2.createVNode(_component_a_form_item, { "wrapper-col": { offset: 8, span: 16 } }, {
                            default: vue_2.withCtx(function () { return [
                                vue_2.createVNode(_component_a_button, {
                                    type: "primary",
                                    "html-type": "submit"
                                }, {
                                    default: vue_2.withCtx(function () { return [
                                        vue_2.createTextVNode("生成")
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["model"]),
                vue_2.createElementVNode("div", null, [
                    vue_2.createVNode(_component_a_textarea, {
                        value: formState.uuids,
                        "onUpdate:value": _cache[1] || (_cache[1] = function ($event) { return ((formState.uuids) = $event); }),
                        placeholder: "请先生成uuid",
                        rows: 4,
                        style: { "height": "400px", "width": "363px", "right": "65px", "font-family": "revert" }
                    }, null, 8, ["value"])
                ])
            ]));
        };
    }
});
